import variables from '@/styles/abstracts/_colors.module.scss'

import i18n from '@/plugins/i18n'
import router from '@/router'

import * as _canKey from '@/helpers/ability/permissions'

/** @param {URL} url */
export function toLogin(url, basePath = '') {
  const token = localStorage.getItem('teamculture:token')

  if (token) {
    url.pathname = basePath + '/login'
    url.searchParams.set('t', token)
  }

  return url.toString()
}

export function genSiteNavigationItens(onClickLogout = () => {}) {
  const CDN_BASE_URL = process.env.VUE_APP_CDN_ASSETS_BASE_URL

  return {
    userLinks: {
      logout: {
        click: onClickLogout,
        text: i18n.t('ui-components.siteNavigation.user.logout'),
      },
      help: {
        url: i18n.t('ui-components.siteNavigation.helpInfoLink'),
        text: i18n.t('ui-components.siteNavigation.help'),
      },
      settings: {
        url: new URL(
          'users/profile',
          process.env.VUE_APP_ENGAGEMENT_LEGACY_LINK
        ).toString(),
        text: i18n.t('ui-components.siteNavigation.user.settings'),
      },
    },
    menuLinks: [
      {
        name: 'Profile',
        url: '/profile',
        image: {
          src: `${CDN_BASE_URL}/images/navigation/home.svg`,
          title: i18n.t('ui-components.siteNavigation.Home'),
          color: variables.yellowColor8,
        },
        permissioned: _canKey.profile,
        tabindex: '1',
        ariaLabel: i18n.t('navBar.accessibility.profile'),
      },
      {
        name: 'Engagement',
        url: '/engagement',
        image: {
          src: `${CDN_BASE_URL}/images/navigation/engagement.svg`,
          title: i18n.t('ui-components.siteNavigation.Engagement'),
          color: variables.redColor11,
        },
        permissioned: [
          _canKey.engagement_climate,
          _canKey.engagement_culture,
          _canKey.engagement_participations,
          _canKey.engagement_groups_comparation,
          _canKey.engagement_benchmark,
          _canKey.profile_opinions,
          _canKey.engagement_opinions,
          _canKey.engagement_action_plans,
        ],
        tabindex: '1',
        ariaLabel: i18n.t('navBar.accessibility.engagement'),
      },
      {
        name: 'Performance',
        url: null,
        image: {
          src: `${CDN_BASE_URL}/images/navigation/performance-active.svg`,
          title: i18n.t('ui-components.siteNavigation.Performance'),
          color: variables.blueColor7,
          current: true,
        },
        permissioned: _canKey.performance,
        customEvents: {
          click: () => {
            const navigation = { name: 'TreeViewPage' }
            if (router.currentRoute.name !== navigation.name) {
              router.push(navigation)
            }
          },
        },
        ariaLabel: i18n.t('navBar.accessibility.performance'),
      },
      {
        name: 'Growth',
        url: '/growth',
        image: {
          src: `${CDN_BASE_URL}/images/navigation/growth.svg`,
          title: i18n.t('ui-components.siteNavigation.Growth'),
          color: variables.greenColor12,
        },
        permissioned: [
          _canKey.growth,
          _canKey.profile_growth,
          _canKey.profile_feedback,
        ],
        tabindex: '1',
        ariaLabel: i18n.t('navBar.accessibility.growth'),
      },
      {
        name: 'Surveys',
        url: process.env.VUE_APP_CUSTOM_SURVEY_LINK,
        image: {
          src: `${CDN_BASE_URL}/images/navigation/advanced-survery.svg`,
          title: i18n.t('ui-components.siteNavigation.Survery'),
          color: variables.pinkColor5,
        },
        permissioned: _canKey.customSurvey,
      },
      {
        name: 'Management',
        url: process.env.VUE_APP_MANAGEMENT_LINK,
        image: {
          src: `${CDN_BASE_URL}/images/navigation/management.svg`,
          title: i18n.t('ui-components.siteNavigation.Management'),
          color: variables.grayColor12,
        },
        permissioned: _canKey.management,
        ariaLabel: i18n.t('navBar.accessibility.management'),
      },
    ],
  }
}
